import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-49309114"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page_header" }
const _hoisted_2 = { class: "formContainer" }
const _hoisted_3 = { class: "d-flex align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_localized_text, {
        type: 'h1',
        localizedKey: "Configurazione",
        text: "Configurazione",
        class: "mb-0"
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          class: "btn btn-primary justify-content-center w-100",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/configuration/employees')))
        }, [
          _createVNode(_component_localized_text, {
            localizedKey: "Dipendenti",
            text: "Dipendenti"
          })
        ]),
        _createElementVNode("button", {
          class: "btn btn-primary justify-content-center w-100 mx-2",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push('/configuration/van')))
        }, [
          _createVNode(_component_localized_text, {
            localizedKey: "VAN",
            text: "VAN"
          })
        ]),
        _createElementVNode("button", {
          class: "btn btn-primary justify-content-center w-100 mx-2",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push('/configuration/headquarter')))
        }, [
          _createVNode(_component_localized_text, {
            localizedKey: "Magazzini",
            text: "Magazzini"
          })
        ]),
        _createElementVNode("button", {
          class: "btn btn-primary justify-content-center w-100",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.push('/configuration/workshift-types')))
        }, [
          _createVNode(_component_localized_text, {
            localizedKey: "Tipologia turni",
            text: "Tipologia turni"
          })
        ])
      ])
    ])
  ], 64))
}